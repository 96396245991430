.section_margin {
  margin-top: 4%;
}
.service_section {
  .service_wraper {
    margin-top: -130px;
    margin-bottom: -30px;
    position: relative;
    z-index: 5;

    .service-item {
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      padding: 12px 30px;
      border-radius: 5px;
      margin-bottom: 30px;
      overflow: hidden;
      position: relative;
      height: 450px;
      z-index: 1;

      &:hover {
        .title {
          color: #ffffff;
        }

        p {
          color: #ffffff;
        }

        .shape-2 {
          opacity: 1;
          visibility: visible;
        }

        .shape-1 {
          opacity: 1;
          visibility: visible;
        }

        .service_icon {
          color: #ffffff;
        }

        content: "";
        position: relative;
        left: 0;
        top: 0;
        background: linear-gradient(148deg, #54d6eb 0%, #dd0505 100%);
        // opacity: 0.9;
      }

      .shape-2 {
        position: absolute;
        right: -135px;
        bottom: 55px;
        opacity: 0;
        transition: all 0.3s linear;
        visibility: hidden;
        z-index: -1;
      }

      .shape-1 {
        width: 235px;
        height: 235px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        top: -80px;
        right: -80px;
        opacity: 0;
        transition: all 0.3s linear;
        visibility: hidden;
        z-index: -1;
      }

      .title {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        margin-top: 12px;
        color: black;
      }
      .service_icon {
        font-size: 50px;
        color: #9f3b35;
      }
      p {
        font-size: 13px;
        line-height: 21px;
        font-weight: 600;
        color: #4c4d56;
        margin-top: 20px;
      }
    }
  }
}

.about_content_7sft {
  .section_title_7sft { 
    .sub_title_7sft {
      font-size: 18px;
      line-height: 30px;
      font-weight: 800;
      color: #9f3b35;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    .title_7sft {
      font-size: 40px;
      line-height: 54px;
      font-weight: 700;
      color: #333333;
    }
  }
  p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: #4c4d56;
    margin-top: 25px;
  }
}

.office_right_7sft {
  .title_7sft {
    font-weight: 800;
  }
  .skill_item_7sft {
    margin-top: 30px;
  }

  .skill_title_7sft {
    font-size: 14px;
    line-height: 24px;
    color: #120e1d;
    font-weight: 800 !important;
  }
}

.skill_header_7sft {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  color: #120e1d;
}

.skill_bar_7sft {
  .bar_inner_7sft {
    width: 100%;
    height: 10px;
    border: 1px solid #d7d7d7;
    margin-top: 5px;
    position: relative;
  }
  .progress_line_7sft {
    height: 6px;
    position: absolute;
    width: 0;
    top: 1px;
    left: 3px;
    background: #9f3b35;
    transition: 3s;
    transition-delay: 1s;
  }
}

.office_bar_7sft {
  margin-bottom: 30px;
}

//counter area
.counter_section_7sft {
  background-image: url("../assets/images/Highlight-banner_USYD.jpg");
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-top: 4%;

  .counter_wrap_7sft {
    margin-top: 30px;
    text-align: center;

    .counter_icons_7sft {
      margin-bottom: 30px;
    }

    .counter_text_7sft {
      p {
        font-size: 14px;
        line-height: 30px;
        font-family: "Manrope", sans-serif;
        font-weight: 400;
        color: #ffffff;
      }

      span {
        font-size: 50px;
        line-height: 30px;
        font-family: "Manrope", sans-serif;
        font-weight: 700;
        color: #ffffff;
      }
    }
  }
}

//fefeatures-section
@keyframes rotate-style {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.features_section_7sft {
  background-image: url("../assets/images/features-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  position: relative;
  z-index: 5;

  .shap1_7sft { 
    height: 50px;
    position: absolute;
    left: 33px;
    top: 85px;
    padding: 0 20px;
    z-index: -1;
  }

  .image_7sft {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
    }
  }
  .features_wrap_7sft {
    padding-top: 140px;
    padding-bottom: 145px;
  }

  .features_content_wrap_7sft {
    .section_title_7sft {
      padding-left: 2rem;
      .sub_title_7sft {
        font-size: 18px;
        line-height: 30px;
        font-weight: 800;
        color: #9f3b35;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 12px;
      }

      .title_7sft {
        font-size: 40px;
        line-height: 54px;
        font-weight: 700;
        color: #333333;
      }
    }
    .features_content_7sft {
      padding-top: 20px;
    }
  }
}

.features_item_7sft {
  display: flex;
  width: 50%;
  margin-top: 35px;
}

.features_text_7sft {
  flex: 1;
  margin-left: 35px;
  max-width: 220px;

  h5{
    font-size: 18px;
    font-weight: 800 !important;
  }

  p{
    font-size: 13px;
  }
}

.feture_ul_7sft {
  display: flex;
  flex-wrap: wrap;
}

//Blog Section ============================== scss

.blog_wrap_7sft {
  .section_title_7sft {
    text-align: center;
    padding-left: 2rem;
    .sub_title_7sft {
      font-size: 18px;
      line-height: 30px;
      font-weight: 800;
      color: #9f3b35;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    .title_7sft {
      font-size: 40px;
      line-height: 54px;
      font-weight: 700;
      color: #333333;
    }
  }

  .single_blog_7sft {
    background: #ffffff;
    box-shadow: 0px 0px 98px 0px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 30px;
    .blog_img_7sft {
      position: relative;
      overflow: hidden;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      img {
        width: 100%;
        transition: all 0.3s linear;
      }
      .top_meta_7sft {
        position: absolute;
        top: 35px;
        left: 30px;
        .date_7sft {
          width: 65px;
          height: 65px;
          line-height: 65px;
          text-align: center;
          background-image: linear-gradient(120deg, #54d6eb 0%, #035cdd 100%);
          color: #fff;
          font-size: 14px;
          line-height: 18px;
          border-radius: 5px;
          display: inline-block;
          text-align: center;
          line-height: 12px;
          padding: 10px;
          span {
            font-size: 28px;
            line-height: 18px;
            font-weight: 500;
            line-height: 30px;
            display: block;
          }
        }
      }
    }
    .blog_content_7sft {
      padding:20px 40px;
      height: 400px;
      .blog_meta_7sft {
        span {
          font-size: 14px;
          line-height: 24px;
          font-weight: 600;
          color: #999999;
          margin-right: 15px;
          i {
            color: #9f3b35;
            margin-right: 5px;
          }
        }
      }
      .title_7sft {
        font-size: 18px;
        line-height: 30px;
        color: #333333;
        padding-right: 15px;
        margin-top: 15px;
        font-weight: 800 !important;
        cursor: pointer;
        transition: all 0.3s linear;
        &:hover {
          color: #9f3b35;
        }
      }
      p {
        font-size: 14px;
        line-height: 26px;
        font-weight: 400;
        color: #4c4d56;
        margin-top: 10px;
      }
    }

    .blog_btn_7sft {
      border-top: 1px solid #ebebeb;
      padding: 15px 40px;
      a {
        text-decoration: none !important;
      }
      .blog_btn_link_7sft {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
        color: #9f3b35;
        transition: all 0.3s linear;
        i {
          transition: all 0.3s linear;
        }
      }
      &:hover {
        i {
          transform: translateX(5px);
        }

        .blog_img_7sft {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

//STAFF SECTION

.section-padding {
  padding-bottom: 145px;
}

.section,
.main-wrapper {
  justify-content: end;
  width: 100%;
}

.section-title {
  max-width: 690px;
  margin: 0 auto;
  .sub-title {
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    color: #9f3b35;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  .title {
    font-size: 40px;
    line-height: 54px;
    font-weight: 700;
    color: #333333;
  }
}

.text-center {
  text-align: center !important;
}

.team-section {
  .team-wrap {
    .team-content-wrap {
      padding-top: 20px;

      .single-team {
        overflow: hidden;
        position: relative;
        .emp_des_7sft {
          position: absolute;
          bottom: 20%;
          left: 0;
          right: 0;
          width: 100%;
          height: 50%;
          background-color: #0d7fc49c;
          // -webkit-clip-path: polygon(0 34%, 0% 100%, 100% 100%);
          // clip-path: polygon(0 34%, 0% 100%, 100% 100%);
          transform: translatex(-106%);
          transition: all 0.3s linear;
          padding: 20px;
          p {
            color: white;
          }
          .emp_icons_7sft {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;
            margin-bottom: 5px;
            span {
              font-size: 14px;
            }
          }
        }
        &:hover {
          .team-img a::before {
            opacity: 1;
            visibility: visible;
          }

          .emp_des_7sft {
            transform: translatex(0);
            transition: all 0.3s linear;
          }

          .team-img a::after {
            opacity: 0.35;
            visibility: visible;
            .social {
              display: block;
              color: #ffffff;
            }
          }

          .team-content {
            .team-social {
              opacity: 1;
              visibility: visible;
              transform: translateY(-55px);
            }
          }

          .social {
            color: #ffffff;
          }
        }

        .team-img a {
          display: block;
          position: relative;
          img {
            width: 100%;
          }
        }
        // .team-img a::before {
        //   content: "";
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 75%;
        //   background-color: #0071dc;
        //   opacity: 0;
        //   visibility: hidden;
        //   transition: all 0.3s linear;
        // }

        // .team-img a::after {
        //   content: "";
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 480px;
        //   background-color: #0071dc;
        //   -webkit-clip-path: polygon(0 34%, 0% 100%, 100% 100%);
        //   clip-path: polygon(0 34%, 0% 100%, 100% 100%);
        //   opacity: 0;
        //   visibility: hidden;
        //   transition: all 0.3s linear;
        // }

        .team-content {
          background: #ffffff;
          padding: 30px;
          margin-top: -70px;
          position: relative;
          width: 85%;
          z-index: 1;
          .designation {
            font-size: 16px;
            line-height: 28px;
            font-weight: 400;
            color: #9f3b35;
          }
          .name {
            font-size: 22px;
            line-height: 28px;
            color: #231e32;
            &:hover {
              color: #9f3b35;
            }
          }
          .team-social {
            position: absolute;
            top: 0;
            left: 25px;
            transform: translateY(-25px);
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s linear;
            .social {
              display: inline-flex;
              li {
                font-size: 20px;
                justify-content: space-between;
                padding: 5px;
                display: inline-block;
                color: #ffffff;
                transform: translate3d(0, 0, 0);
                transition: all 0.3s linear;
                &:hover {
                  cursor: pointer;
                  transform: translateY(-5px);
                }
              }
            }
          }
        }
      }
    }
  }
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container-pointer-events {
  touch-action: pan-y;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

//BUTTON ANIMATION START
.case-study-btn {
  margin-top: 5px;
  .btn_7sft {
    text-decoration: none;
    display: inline-block;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: #9f3b35;
    border-color: transparent;
    color: #ffffff !important;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    height: 60px;
    line-height: 56px;
    padding: 0px 55px;
    border-style: solid;
    box-shadow: none;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    z-index: 1;
    position: relative;
    a {
      &:hover {
        text-decoration: none;
        color: #9f3b35 !important;
      }
    }
    &:hover {
      box-shadow: none;
      color: #9f3b35 !important;
      &::after {
        transform: skewX(45deg) scale(1, 1);
      }
    }
    &:after {
      position: absolute;
      content: "";
      z-index: -1;
      display: block;
      left: -20%;
      right: -20%;
      top: -4%;
      height: 150%;
      width: 150%;
      bottom: 0;
      background: #ffffff;
      transform: skewX(45deg) scale(0, 1);
      transition: all 0.5s ease 0s;
    }
  }
}
.text-center {
  text-align: center !important;
}
//BUTTON ANIMATION START

//STAFF SECTION END

///Our Team Scss

.card-resume__photo {
  background: linear-gradient(-48deg, #54d6eb 0%, #035cdd 100%);
  border-radius: 23px;
  padding: 5px;
  margin-bottom: 0px;
  overflow: hidden;
  width: 100%;
  height: 250px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center top;
    border: 5px solid #fff;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 430px;
    background: linear-gradient(-48deg, #54d6eb 0%, #035cdd 100%);
    -webkit-clip-path: polygon(0 34%, 0% 100%, 100% 100%);
    clip-path: polygon(0 34%, 0% 100%, 100% 100%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s linear;
  }

  &:hover {
    &::before {
      opacity: 0.8;
      visibility: visible;
    }
  }
}
