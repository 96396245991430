.header {
  position: fixed;
  left: 0;
  top: 0;
  background: #01030a3b;
  width: 100%;
  z-index: 999;
  padding: 20px 0px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
  .logo {
    width: 120px;
  }

  .active {
    color: #9f3b35 !important;
  }
  .main_menu {
    font-weight: 800 !important;
    transition: 0.2s;
    position: relative;
    &:hover {
      color: #9f3b35 !important;
    }
  }

  .main_services {
    &:hover {
      .sub_menu_7sft {
        opacity: 1;
        visibility: visible;
        top: 70px;
      }
    }
    .chevron_icon{
      margin-left: 5px;
    }
  }
  .sub_menu_7sft {
    position: absolute;
    left: 0;
    top: 110%;
    width: 270px;
    background: black;
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.5);
    padding: 20px 0;
    border-radius: 5px;
    border-top: 3px solid #9f3b35;
    opacity: 0;
    z-index: 99;
    visibility: hidden;
    transition: all 0.3s linear;
    a {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #9f3b35;
        width: 10px;
        height: 2px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
      }

      &:hover {
        color: #9f3b35;
        padding-left: 35px;
        &::before {
          opacity: 1;
          visibility: visible;
          left: 15px;
        }
      }
    }

    .active {
      color: #9f3b35;
      padding-left: 35px;
      &::before {
        opacity: 1;
        visibility: visible;
        left: 15px;
      }
    }
  }

}

.banner {
 // background-image: url("../assets/images/hero-1.jpg");
  height: 1120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  .shape-1 {
    width: 417px;
    height: 417px;
    background: linear-gradient(-48deg, #fbedec 0%, #dc0e00 100%);
    opacity: 0.9;
    border-radius: 50%;
    position: absolute;
    top: 144px;
    left: 50px;
    z-index: -1;
    -webkit-animation: action 0.5s infinite  alternate;
    animation: action 0.5s infinite  alternate;
  }

  .shape-2 {
    width: 578px;
    height: 315px;
    background-image: linear-gradient(290deg, #54d6eb 0%, #0f0fcb 100%);
    transform: skewY(15deg);
    position: absolute;
    left: 3.5%;
    top: 50%;
    z-index: -1;
  }

  .shape-3 {
    width: 578px;
    height: 315px;
    background: #ffffff;
    opacity: 0.2;
    transform: skewY(15deg);
    position: absolute;
    left: 12%;
    top: 40%;
    z-index: -1;
  }
  .shape-4 {
    width: 447px;
    height: 98px;
    background: linear-gradient(290deg, #54d6eb 0%, #02ba02 100%);
    opacity: 0.6;
    transform: skewY(15deg);
    position: absolute;
    right: -10%;
    top: 76%;
    z-index: -1;
  }

  .shape-5 {
    width: 318px;
    height: 111px;
    background: linear-gradient(290deg, #54d6eb 0%, #0f0fcb 100%);
    opacity: 0.6;
    transform: skewY(15deg);
    position: absolute;
    right: -6%;
    top: 71%;
    z-index: -1;
  }

  h5 {
    font-size: 16px;
    line-height: 30px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ffffff;
  }

  h3 {
    font-size: 50px;
    line-height: 65px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 10px;
  }

  .header-info {
    justify-content: end;

    .info-text {
      .info-icon {
        color: #335de6;
        font-size: 30px;
        margin-right: 20px;
      }
      .number {
        display: block;
        font-size: 14px;
        line-height: 18px;
        color: #090d2f;
      }
    }
  }
}

.bm-overlay{
  top:0px;
  left: 0px; 
}

.bm-menu-wrap{
  top:0px !important;
  left: 0px !important;
  background-color: #000c29 !important;
  padding: 20px;
  color: #000 !important;
  .bm-item-list{
    a{
      color: #ffffff !important;
    }
  }
  
}

.mobile_menu{
  display: none;
}

.desktop_menu{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

// burger menu<=======================>

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  top: 36px;
}

.bm-burger-bars {
  background: white;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.video_class{
  width: 100%;
  border-bottom-left-radius:12%;
  border-bottom-right-radius:12%;
  filter: blur(2px);
  filter: brightness(0.4);
  object-fit: cover;
}
.video_class_others{
  width: 100%;
  height: 500px;
  border-bottom-left-radius:12%;
  border-bottom-right-radius:12%;
  filter: blur(2px);
  filter: brightness(0.4);
  object-fit: cover;
}
.hero_video{
  z-index: -2;
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.hero_video_other{
  z-index: -2;
  width: 100%;
  overflow: hidden;
  height: 100%;
  top: 0;
  left: 0;
}


@-webkit-keyframes action {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

@keyframes action {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
