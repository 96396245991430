.page-banner-section {
    //background-image: url("../assets/images/about-banner.jpg");
    padding-top: 80px;
    min-height: 550px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .shape-banner2 {
        width: 545px;
        height: 545px;
        border: 3px solid #9f3b35;
        opacity: 0.9;
        border-radius: 50%;
        position: absolute;
        left: -10%;
        bottom: -40%;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(-48deg, #54d6eb 0%, #035cdd 100%);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transform: scale(0.8);
        }
    }
  
    .shape-1 {
      width: 417px;
      height: 417px;
      background: linear-gradient(
  150deg
  , #54d6eb 0%, #02ba02 100%);
      opacity: 0.9;
      border-radius: 50%;
      position: absolute;
      top: 115px;
      left: 50px;
      z-index: -1;
    }

    .page-banner{
        .breadcrumb {
            margin-bottom: 0;
            margin-top: 10px;
            .breadcrumb-item {
                color: #ffffff;
                font-size: 16px;        
                font-weight: 700;
            }
        }
        .title {
            font-size: 60px;
            line-height: 1.25;
            display: inline-block;
            font-weight: 700;
            color: #ffffff;
            position: relative;
            z-index: 1;
            &:before {
                content: "";
                width: 75px;
                height: 75px;
                border-radius: 50%;
                background-image: linear-gradient(-48deg, #54d6eb 0%, #035cdd 100%);
                opacity: 0.9;
                position: absolute;
                left: -25px;
                top: -20px;
                z-index: -1;
            }
        }
    }
}

.our_team_backImage{
    // background-image: url("../assets/images/our_team.jpg");
    background-size: 100%; 
    background-position: center;
    position: relative;
    height:750px;
    &::before{
        content: '';
        background-color: #00000075;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}


.services_backImage{
    // background-image: url("../assets/images/services.jpg");
    position: relative;
    &::before{
        content: '';
        background-color: #00000075;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

.career_backImage{
    // background-image: url("../assets/images/career.jpg");
    position: relative;
    &::before{
        content: '';
        background-color: #00000075;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}


.contact_backImage{
    // background-image: url("../assets/images/contact.jpg");
    position: relative;
    &::before{
        content: '';
        background-color: #00000075;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}


.about_backImage{
    // background-image: url("../assets/images/about.jpg");
    position: relative;
    &::before{
        content: '';
        background-color: #00000075;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

.event_backImage{
    // background-image: url("../assets/images/about-banner.jpg");
    position: relative;
    background-position: center;
    &::before{
        content: '';
        background-color: #00000075;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}
