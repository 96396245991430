@media only screen and (max-width: 575px) {
  .foot_text {
    text-align: center;
  }

  .single_contact_info_7sft {
  .info_contact_7sft{
    p{
      font-size: 12px;
    }
  }
}
  

  .foot_icons {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
  }
  .our_team_backImage {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
  }
  .mobile_menu {
    display: block !important;
  }

  body {
    overflow-x: hidden !important;
  }
  .bm-burger-button {
    right: 20px !important;
  }

  .desktop_menu {
    display: none !important;
  }
  .banner {
    height: 780px !important;

    .video_class{
      border-bottom-left-radius: 0% !important; 
    border-bottom-right-radius: 0% !important;

    }
    .shape-1 {
      width: 250px !important;
      height: 250px !important;
      left: 30px !important;
    }

    h5 {
      font-size: 14px !important;
      line-height: 26px !important;
    }
    h3 {
      font-size: 36px !important;
      line-height: 48px !important;
    }
  }

  .counter_section_7sft {
    height: 100% !important;
  }

  .about_content_7sft {
    .section_title_7sft {
      .title_7sft {
        font-size: 27px !important;
        line-height: 45px !important;
      }
    }
  }

  .features_section_7sft {
    .shap1_7sft {
      img {
        width: 100% !important;
      }
    }
    .features_content_wrap_7sft {
      .section_title_7sft {
        padding: 0px !important;
        .title_7sft {
          font-size: 28px !important;
          line-height: 36px !important;
        }
      }
    }
  }

  .features_item_7sft {
    width: 100% !important;
  }

  .page-banner-section {
    .shape-banner2 {
      width: 300px !important;
      height: 300px !important;
      left: -28% !important;
      bottom: -21% !important;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .single_contact_info_7sft {
    .info_contact_7sft{
      p{
        font-size: 12px;
      }
    }
  }

  .foot_text {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .our_team_backImage {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
  }

  .foot_icons {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
  }
  .mobile_menu {
    display: block !important;
  }
  body {
    overflow-x: hidden !important;
  }
  .bm-burger-button {
    right: 20px !important;
  }
  .desktop_menu {
    display: none !important;
  }
  .banner {
    height: 780px !important;

    .video_class{
      border-bottom-left-radius: 0% !important; 
    border-bottom-right-radius: 0% !important;

    }
    .shape-1 {
      width: 250px !important;
      height: 250px !important;
      left: 30px !important;
      top: 30% !important;
    }

    h5 {
      font-size: 14px !important;
      line-height: 26px !important;
    }
    h3 {
      font-size: 36px !important;
      line-height: 48px !important;
    }
  }

  .counter_section_7sft {
    height: 100% !important;
  }

  .about_content_7sft {
    .section_title_7sft {
      .title_7sft {
        font-size: 27px !important;
        line-height: 45px !important;
      }
    }
  }

  .features_section_7sft {
    .shap1_7sft {
      img {
        width: 100% !important;
      }
    }
    .features_content_wrap_7sft {
      .section_title_7sft {
        padding: 0px !important;
        .title_7sft {
          font-size: 28px !important;
          line-height: 36px !important;
        }
      }
    }
  }

  .page-banner-section {
    .shape-banner2 {
      width: 300px !important;
      height: 300px !important;
      left: -28% !important;
      bottom: -21% !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .single_contact_info_7sft {
    .info_contact_7sft{
      p{
        font-size: 12px;
      }
    }
  }

  .foot_text {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .our_team_backImage {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
  }

  .foot_icons {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
  }
  .mobile_menu {
    display: block !important;
  }
  .bm-burger-button {
    right: 20px !important;
  }

  body {
    overflow-x: hidden !important;
  }

  .desktop_menu {
    display: none !important;
  }
  .banner {
    height: 780px !important;

    .video_class{
      border-bottom-left-radius: 0% !important; 
    border-bottom-right-radius: 0% !important;

    }
    .shape-1 {
      width: 250px !important;
      height: 250px !important;
      left: 30px !important;
    }

    h5 {
      font-size: 14px !important;
      line-height: 26px !important;
    }
    h3 {
      font-size: 36px !important;
      line-height: 48px !important;
    }
  }

  .counter_section_7sft {
    height: 100% !important;
  }

  .about_content_7sft {
    .section_title_7sft {
      .title_7sft {
        font-size: 27px !important;
        line-height: 45px !important;
      }
    }
  }

  .features_section_7sft {
    .shap1_7sft {
      img {
        width: 100% !important;
      }
    }
    .features_content_wrap_7sft {
      .section_title_7sft {
        padding: 0px !important;
        .title_7sft {
          font-size: 28px !important;
          line-height: 36px !important;
        }
      }
    }
  }
  .page-banner-section {
    .shape-banner2 {
      width: 300px !important;
      height: 300px !important;
      left: -28% !important;
      bottom: -21% !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1366px) {
  .single_contact_info_7sft {
    .info_contact_7sft{
      p{
        font-size: 12px;
      }
    }
  }
  
  .our_team_backImage {
    height: 590px !important;
  }

  .service_section{
    .service_wraper{
      .service-item{
        height: 529px !important;
      }
    } 
  }
  
  .features_section_7sft{
    .shap1_7sft{
      left: 61px !important;
      img{
        width: 500px !important;
      }
    }
  } 
}
