.test-back-img {
  background-image: url("../assets/images/testi-bg.jpg");
}
.testimonial-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.section-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section {
  flex: left;
  width: 100%;
}

.testimonial-wrap {
  .section-title {
    max-width: 690px;
    margin: 0 auto;
    .sub-title {
      font-size: 18px;
      line-height: 30px;
      font-weight: 800;
      color: #9f3b35;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    .title {
      font-size: 40px;
      line-height: 54px;
      font-weight: 700;
      color: #ffffff;
    }
  }
}

.single-testimonial {
  background: #ffffff;
  padding: 50px;
  border-radius: 5px;
  position: relative;
  margin: 18px 10px;
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleY(1.1) scaleX(0.85);
    border-radius: 5px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleY(1.05) scaleX(0.92);
    border-radius: 5px;
  }

  .testimonial-content {
    max-width: 285px;
    .title2 {
      font-size: 22px;
      line-height: 30px;
      color: #9f3b35;
    }
    .testimonial-author-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .testimonial-author {
        display: flex;
        align-items: center;
        .author-img {
          img {
            border-radius: 50%;
          }
        }
        .author-text {
          flex: 1;
          margin-left: 20px;
          .name {
            font-size: 16px;
            line-height: 20px;
            color: #231e32;
          }
          .designation {
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            display: block;
            color: #9f3b35;
          }
        }
      }
    }
  }
}
