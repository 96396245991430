.footer-section {
    background: #000c29;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("../assets/images/footer_bg.jpg");
    .section, .main-wrapper {
        width: 100%;
    }
}



.footer-widget-wrap {
    padding-top: 190px;
    padding-bottom: 70px;
}

.footer-logo {
    max-width: 170px;
}

.logo {
    width: 120px;
  }

.widget-info {
    padding-top: 20px;
}

.widget-info ul li .info-text {
    line-height: 20px;
    color: #c4c5c7;
}

.footer-widget {
    margin-top: 30px;
}

.footer-widget .footer-widget-title {
    color: #ffffff;
}

.widget-link {
    padding-top: 22px;
}

.widget-link .link li {
    margin-top: 7px;
}

.widget-link .link li a {
    color: #c4c5c7;
    transition: all 0.3s linear;
}

ul, ol {
    padding: 0;
    list-style: none;
    margin: 0;
}

.footer-copyright-area {
    border-top: 1px solid #1a253f;
    padding-top: 30px;
    padding-bottom: 30px;
}

.copyright-text {
    color: #ffffff;
    p{
        margin: 0px !important;
    }
}

.copyright-social {
    text-align: right;
}

.link{
    .navlnk{
        color: #ffffff;
        margin-bottom: 10px;
        transition: 0.3s;
        cursor: pointer;
        text-decoration: none;
        outline: none;
        &:hover{
            color: #9f3b35;
            padding-left: 10px;
        }
    }
    // li{
    //     color: #ffffff;
    //     margin-bottom: 10px;
    //     transition: 0.3s;
    //     cursor: pointer;
    //     &:hover{
    //         color: #9f3b35;
    // padding-left: 10px;
    //     }
    // }
}

.social{
    display: inline-flex;
}

.info-icon{
    color: #335de6;
    font-size: 30px;
}

.info-text{
    margin: 10px;
}

.foot_icon{
    font-size: 23px;
        color: #ffffff;
        transition: 0.3s;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: #15203B;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        &:hover{
            color: #335de6;
        }
}

.foot_icons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }