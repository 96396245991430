body {
  font-family: "Poppins" !important;
  overflow-x: hidden !important;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/poppin.woff2) format("woff2");
}

html,
body {
  height: 100% !important;
  margin: 0 !important;
}
.slide_img{
  img{
    width: 100%;
    height: 100%;
  }
  
}
.loader {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: #9f3b35;
}

.scrollButton {
  display: none;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 999999999;
  outline: none;
  background-color: #ffff;
  color: #000c29;
  cursor: pointer;
  border: 2px solid #000c29;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
  text-align: center;
  padding: 10px 0px;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}


.list_content{
  padding-left: 2rem !important;
  li{
    list-style: square !important;
    list-style-type: square;
    h6{
      font-size: 14px !important;
    }
  }
}

.forget-btn {
  cursor: pointer !important;

  &:hover {
    color: #9f3b35;
  }

}

.event_img {
width: 100%;
height: 100%;
img {
  width: 100%;
height: 100%;
}
}

.data_not_found{
  border:1px solid #9f3b35;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: #ffff;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 20px;
}

.drop{
  padding: 10px !important; 
  font-size: 14px;
li:nth-child(2){
  margin-top: 10px !important;
  border-top:1px solid black ;
  border-bottom:1px solid black ;
}
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.icon_style{
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.flex{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}